import './app.scss';
import background from './illustrator-background.jpg';
import { Button, Layout, Modal, notification, Result } from 'antd';
import emailjs from '@emailjs/browser';
import { useEffect, useState, useRef } from 'react';

const { Footer, Content } = Layout;

function App() {
  const form = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [, forceUpdate] = useState({});
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    forceUpdate({});
  }, []);
  
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_bojmey2', 'template_decw9bd', form.current, 'WyXi8WtfWEUFwrJnP')
      .then((result) => {
          if(result.status === 200) {
            notification['success']({
              message: 'Başarılı',
              description:
                '',
            });
            setSuccess(true);
          }
      }, (error) => {
        notification['error']({
          message: 'Başvurunuz yapılamamıştır.',
          description:
            'Lütfen tekrar deneyiniz.',
        });
      });
  };

  return (
    <Layout>
      <Content>
      <div class="scroll-downs">
          <div class="mousey">
            <div class="scroller"></div>
          </div>
        </div>
          <section className="white-bg" style={{marginBottom: 20}}>
            <img src={background} alt="illustrator-platform" />
          </section>
          <section>
          <div className='container'>
              <div className="content-item">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLScwzjNQu7ptA-9X5NvSgC0BZOhihx4cDGi9dlAqJhYieIekzw/viewform" className="primary-link">HEMEN BAŞVUR</a>
              </div>
              <div className="content-item text-item">
                <h2>
                  ILLUSTRATORS PLATFORM ACADEMY ["iP Academy"] NEDİR?
                </h2>
                  <p>
                  iP Academy, Kültür ve Turizm Bakanlığı’nın Kültür Endüstrilerinde Gençlerin Yaratıcılık ve
                  Girişimcilik Potansiyelinin Artırılmasına Yönelik Destek Programı kapsamında oluşturduğu “Gelecek Gençlerin”
                  adlı program tarafından desteklenmektedir.
                  </p>
                  <p>
                  iP Academy, ülkemizde yaratıcı kültür endüstrileri alanlarına mensup sektörlerde görev alan illüstratörlerin ve girişimcilerin, 
                  yaratıcı kültür endüstrileri alanında sürdürülebilir ekonomi sağlamalarına destek olur. 
                  Yaratıcılık ve girişimcilik kabiliyetlerini destekler, gençlerin kişisel gelişimi ve meslek ediniminin 
                  sağlanmasına dair faaliyetler gerçekleştirmenin yanı sıra, iş dünyası, sektör temsilcileri ve eğitim kurumlarıyla 
                  yaptığı işbirliği ile ülkemiz kültür endüstrilerine katkı sunmaktadır.
                  </p>
              </div>
              <div className="content-item text-item">
                <h2>
                  AMACI:
                </h2>
                <p>
                Bu dönemki programın amacı katılımcıların, yaratıcı kültür endüstrileri alanlarından biri olan yayıncılık 
                sektöründe kariyer hedefleyen gençlerin ihtiyaç duydukları bilgi, danışmanlık ve uzmanlığa erişebilirliğini sağlamak, 
                resimli kitap ve çizgi roman kategorilerinde uluslararası pazarlara giriş imkânlarını arttırmak, alanda branşlaşma,
                profesyonelleşme, markalaşma ve kendi eserlerini yayına hazırlama noktasında, kendisine sürdürülebilir bir ekonomi sağlamasını ve 
                meslek edinimini desteklemektir.  
                </p>
                <p>
                Genç girişimcilerin veya genç sanatçıların yaratıcı kültür endüstrileri alanında ülke ekonomisine katkı sağlayacak donanımı ve ehliyeti 
                kazanması, hem yurtiçi hem de uluslararası pazarlara giriş imkânı verecek mecralarla buluşturulması, yerel kültürel zenginliklerimizin yenilikçi 
                bir yorumla ve sürdürülebilir bir yapı ile ülkemize ve dünyaya tanıtma potansiyelinin geliştirilmesi projenin nihai çerçevelerini oluşturur.
                </p>
              </div>
            </div>
          </section>
          <section>
          <div className="container">
            <div className="content-item text-item">
                <h2>
                  PROGRAM HAKKINDA:
                </h2>
                <p style={{marginBottom: 30}}>
                  iP Academy tarafından burs verilecek olup, program dahilindeki eğitimleri ücretsiz olacaktır. 
                  Bu destek programı kapsamında başvuranlar arasından jürinin programa uygun gördüğü adaylar seçilecektir. 
                </p>
                <p style={{marginBottom: 30}}>
                Eğitim dili Türkçe olmakla beraber uluslararası konuk ve eğitimciler olduğunda İngilizce
                etkinlikler düzenlenebilir.
                </p>
                <p style={{marginBottom: "-5px"}}>
                  <p>Eğitim çevrimiçi (online) düzenlenecek olup katılımcıların durumuna göre hibrit model de uygulanabilecektir.</p>
                  <p><b>Kontenjan: </b>70 kişi</p>
                  <p><b>Kategori: </b> Çizgi Roman Yazar Çizerliği, Resimli Çocuk Kitabı Yazar Çizerliği </p>
                  <p><b>Eğitim Süresi: </b> Grup 1:45 Saat Grup 2: 45  saat Toplam: 90 saat</p>
                </p>
                <p>Eğitimlerin haftada iki gün yapılması öngörülmektedir. Ders saatleri; başvurular tamamlandıktan sonra, katılımcı profiline göre belirlenecek olup planlama da profesyonel çalışan katılımcıların mesai saatleri göz önünde alınacaktır.</p>
                <p style={{marginTop: 20}}>Program, T.C. vatandaşı 20-29 yaş arasındaki adayların başvurusuna açıktır. </p>
                <p>Başvurular çevrimiçi (online) olarak alınır.</p>
                <p>Başvuru sırasında yüklenmesi gereken belgelere dikkat edilmelidir. </p>
                <p>Niyet mektubu, portfolyo ve özgeçmiş dökümanları eksik olan başvurular değerlendirmeye alınmayacaktır.</p>

                <h2><b>DERS İÇERİĞİ:</b></h2>
                <p>Konvansiyonel</p>
                <p>Dijital</p>
                <p>Karma teknik</p>
                <p>Analog tekniklerle başlayıp dijital devam edebilir…</p>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
            <div className="content-item text-item">
            <h2>KİMLERE ÖNERİLİR: </h2>
            <p>
              iP Academy bu programı, kitap yayınlamak isteyen veya bu alanda meslek sahibi olmak isteyen veya 
              resimli kitap / çizgi roman alanında bir süredir çalışan ve/veya alana yeni giriş yapmış, temel çizim tekniklerine hâkim adayların,
              yurtdışına açılabilir standartlarda alanda ilerlemek isteyen adaylara göre planlanmıştır.
            </p>

            <p><b>Resimli kitap veya çizgi roman kategorilerinde </b></p>
            <ul>
              <li>
                Kitap yayınlamak isteyen,
              </li>
              <li>
                Yurtdışına açılabilir standartlarda alanda ilerlemek, 
                yurtdışına bu kategorilerde iş yapmak ve  yurtdışında kitap satışını gerçekleştirmek isteyen,
              </li>
              <li>Yazar ve/veya çizer olarak bu alanları meslek edinmiş veya meslek edinmek isteyen,</li>
              <li>Elinde yayına hazırlama aşamasında projesi  veya bir kitap fikri ve hikâyesi olan, </li>
              <li>Yenilikçi model ve araçlarla tanışmak isteyen,  yaratıcı eser üretme becerilerini geliştirecek eğitimlere ihtiyaç duyan , kişilere uygun bir programdır.</li>
            </ul>
              <p><b>Ayrıca:</b> </p>
              <ul>
                <li>
                  Farklı sektör ve alanlarda çalışsanlara; akademik çalışanlara, reklam ajansında çalışanlara, oyun sektöründe çalışanlara, mimarlara, grafik tasarımcılara, 
                  resim öğretmenlerine, art direktörlere, 
                </li>
                <li>
                  Ya da yeni mezun olsa da, yayıncılık sektöründe kendi eserlerini yaratmak isteyenlere,  bu alanlarda meslek edinmek isteyenlere, fanzin çalışmaları olanlara, 
                  bir vesileyle resimli kitap veya çizgi roman projelerinde yer alanlara, bir süredir başkalarının hikâyelerine kitap resimleyen ancak artık kendi kitabını yazıp çizmek isteyenlere, 
                  yayıncılık dünyasında eserleriyle yazar ve/veya çizer olarak yerini almak isteyenlere tavsiye edilir.
                </li>
              </ul>
              <p>Katılabilecek kişilerin sahip olması, gereken şekli şartlara ilişkin nitelikler aşağıdaki gibidir:</p>
          </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="content-item text-item">
                  <h2 style={{marginBottom: 20}}>
                    Başvuru Kriterleri: 
                  </h2>
                  <p><b>Aşağıda belirtilen kriterlerden en az birine sahip aday katılımcılar başvurabilir: </b></p>
                  <p>&#x2022; Yayımlanmış kitap projeleri, dahil olduğu kitap projeleri olan</p>
                  <p>&#x2022; Elinde yayına hazırlamak istediği kitap projesi olan</p>
                  <p>&#x2022; İllüstratörler ve sessiz kitaplar için hikâye konusu ve/veya sinopsisi olan</p>
                  <p>&#x2022; Hazır bir öykünün resimli kitaba veya çizgi romana dönüşmesinde illüstrasyonu yapabilecek yetkinliğe sahip olan</p>
                  <p>&#x2022; Hikâyesi olsun ya da olmasın temel çizim tekniklerine ve illüstrasyon becerisine sahip olan.</p>
                  <p>&#x2022; Halihazırda yazar-çizer olan, hikâye kurgusu olan resimli kitap /çizgi roman illüstratörü olan </p>
                  <p>&#x2022; Resimli kitap ve çizgi roman alanında düzenli eser yayınlamak isteyen ve bu alanlarda meslek edinmek isteyen farklı bölümlerden GSF mezunları</p>
                  <p>&#x2022; Resimli kitap ve çizgi roman için hikâye fikri olan (öyküsü hazır çocuk kitabı yazarı, çizgi roman yazarı)</p>
                </div>
            </div>
          </section>
          <section className="white-bg">
            <div className="container">
              <div className="content-item text-item">
                <h2>
                  ARANAN YETKİNLİKLER: <br />
                  <p><i>Bu yetkinliklerden en az birini karşılayan aday katılımcılar başvurabilir:</i></p>
                </h2>
                <p>Üniversitelerin GSF’lerinden  mezun olmak  <br />
                     <b>veya</b> GSF mezunu değilse, alanda kendini geliştirmiş ve illüstrasyonla bağlantılı en az 3 yıldır iş üretmeken, ve  <br />
                     analog tekniklerden (suluboya, karakalem, çini, pastel,  akrilik vb.) birini iyi derece kullanabiliyor olmak <br />
                    <b>ve/veya</b> dijital tekniklerden (Photoshop, Procreate, InDesign, Illustrator, Affinity, Designer vb.) birini iyi derecede kullanabiliyor olmak <br />
                   Temel grafik tasarım becerisine sahip olmak (şart değil, olsa iyi olur)
                </p>
              </div>
              <div className="content-item text-item">
                <h2>BAŞVURU ESNASINDA GEREKLİ BELGE VE BİLGİLER: </h2>
                <p>
                Özgeçmiş <br /> 
                Portfolyo <br />
                Niyet Mektubu (Katılımcı adayın neden bu programa katılmak istediği ve beklentisi, bu alanda sahip olduğu deneyim ve varsa referansları)
                En az 3 sayfa birbirini takip eden ve bir hikâye kurgusu olan çizim (metinli ya da metinsiz) , <br />
                En az 1 en fazla 2 kitap hikâye konusu, sinopsis (lütfen resim eklemeyin ve resimli kitap mı, çizgi roman mı belirtin)<br />
                </p>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="content-item text-item">
                <h2>BAŞVURUYA İLİŞKİN KOŞUL ve ŞARTLAR:</h2>
                <p> 
                &#x2022; Başvuru sahiplerinin bu alana ilgi duyduğunu gösteren bir özgeçmişe veya portfolyoya sahip
                olması ve söz konusu belgeleri seçici kurul görüşmeleri esnasında istenmesi halinde ibraz edecek durumda
                olması, <br />
                &#x2022; Adayların 26 Aralık 2022 - 14 Nisan 2023 tarihleri arasında düzenlenecek derslere % 85
                oranında katılım sağlamayı yazılı biçimde taahhüt edebilecek olması, <br />
                &#x2022; Adayların 17-22 Nisan 2023 tarihleri arasında düzenlenecek yayıncı - çizer buluşmaları kapsamında projesini göstermek üzere yayıncı-editör showcase programına ve,  24 Nisan 2023 -– 30 Mayıs 2023  tarihlerinde düzenlenecek sergiye katılım göstermeyi yazılı biçimde taahhüt edebilecek olması,<br />
                &#x2022; Adayların kişisel kullanımları için ve çevrimiçi-çevrimdışı katılım sırasında eğitim programının gerektirdiği teknolojik altyapının (bilgisayar, çizim tableti, özel yazılımlar vb.) ve analog çalışacak olanların ihtiyaç duyacağı malzemelerin (eskiz defteri, kalem, boya kalemleri, boya malzemeleri vb.) kendileri tarafından sağlanması, <br />
                <br />

                Başvuru sırasında ibraz edilen bilgi ve belgelerin doğru kişiye ait olduğu ve başvuru
                koşullarının kabulü formda işaretlenerek teyit edilmelidir. Portfolyo gibi önemli eksik belge,
                hatalı ya da uygun olmayan yaş bildirimi olması durumunda başvuru geçersiz sayılacaktır. <br />

                Başvuru aşamasından sonra adaylarla gerekli durumlarda mülakat yapılacaktır. <br />

                Programa kabul edilen adaylar için “asil” liste ve “yedek” liste oluşturulur. <br />
                
                iP Academy eğitimlerine katılmaya hak kazanan adaylardan kayıt sırasında ek belge
                istenebilir. Bunların duyurusu kayıt döneminden önce yapılacaktır.
                </p>
                <p>
                Programın %85 devam zorunluluğu vardır. Devam göstermeyen, eğitim sonu değerlendirmesinde başarısız olan katılımcılar sertifika almaya hak kazanamazlar. Yayıncı buluşmasına ve “showcase programına” katılım sağlayamazlar.
                Ayrıca adayların eğitimi başarı ile tamamlaması ve sertifikaya hak kazanmak için bir kitap projesi altyapısını başlatması, en az bir çalışması ile program sonu sergi projesine katılım ve katkı sağlaması mecburidir.
                Dersleri harici veya dahili herhangi bir ekipman ile kaydetmek, ticari amaçlar için kullanmak
                ve yayınlamak, eğitim programı akışını kopyalamak ve workshop vb. programlarda ticari amaçla kullanmak yasaktır.
                iP Academy öngörülemeyen durumlar nedeniyle takvim ya da programda değişiklik yapma
                hakkını katılımcıları mümkün olan en kısa sürede bilgilendirmek koşulu ile saklı tutar.
                </p>
                <p>Program adayları başvuru yaptıklarında yukarıda listelenen koşulları kabul etmiş olurlar.</p>
              </div>
            </div>
          </section>
      </Content>
      <Footer />
    </Layout>
  );
}

export default App;
